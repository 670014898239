<template>
    <svg :viewBox="`0 0 ${width} ${height}`" :width="`${emWidth}em`" :height="`${emHeight}em`" focusable="false" role="img" fill="currentColor">
        <component :is="illustration" @dimensions="dimensions"/>
    </svg>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const illustration = defineAsyncComponent(() => import(`@/assets/icons/${props.name}.vue`));
        return { illustration };
    },
    methods: {
        dimensions(data) {
            this.width = data.width;
            this.height = data.height;
        },
    },
    data() {
        return {
            width: 0,
            height: 0,
        };
    },
    computed: {
        emWidth() {
            return this.width >= this.height ? 1 : (this.width / this.height).toFixed(2);
        },
        emHeight() {
            return this.height >= this.width ? 1 : (this.height / this.width).toFixed(2);
        },
    },
};
</script>
