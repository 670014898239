import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: 'AIzaSyDiqnhPGcqPcL66cqYbw4V_mmgBz7vJHpw',
    authDomain: 'selvbygger-plus.firebaseapp.com',
    databaseURL: 'https://selvbygger-plus.firebaseio.com',
    projectId: 'selvbygger-plus',
    storageBucket: 'selvbygger-plus.appspot.com',
    messagingSenderId: '196297040418',
    appId: '1:196297040418:web:bc02408faa69e570f0207b',
    measurementId: 'G-4F8D36Q27R',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Utils
const auth = firebase.auth();
const storage = firebase.storage().ref();
const { FieldValue } = firebase.firestore;
const db = firebase.firestore();
const httpsRequest = firebase.app().functions('europe-west3');
const usersCollection = db.collection('users');
const convertDate = (date = new Date()) => firebase.firestore.Timestamp.fromDate(date);

const uploadFile = (file = null, name = '', projectId = '') => {
    const timestamp = new Date().getTime();
    const path = `projects/${ projectId }/${ timestamp }_${ name }`;
    storage.child(path).put(file);
    return { name, path };
};

const uploadLocalFile = async (path = '', name = '', projectId = '') => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', path);
    xhr.responseType = 'blob';

    xhr.onload = () => {
        const { status, response } = xhr;
        if (status === 200) resolve(uploadFile(response, name, projectId));
        else reject(status);
    };

    xhr.send();
});

const downloadFile = async (path = '') => {
    const url = await storage.child(path).getDownloadURL();
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', url, true);

        xhr.onload = () => {
            const { status, response } = xhr;
            if (status === 200) resolve(window.URL.createObjectURL(response));
            else reject(status);
        };

        xhr.send();
    });
};

const isAdminUser = () => {
    const uids = [
        'H27vcrzHrOTpJUsMeFV99DpHhNi2', // mm.dkurt@gmail.com
        'MP3lCkh0FoV42pZjwRGm52uRY8N2', // rm@selvbyggerplus.dk
        '0PVUdUxaILTrF5fxiegCP8oOKMw1', // kent_poulsen@hotmail.com
        'H9NkYBOQiWNzevxEGGlqdzblt3W2', // carinarfisker@gmail.com
        'T72ziFlJVgahe6bbC4sOJsDJC4p1', // mrosener@me.com
    ];

    return uids.includes(auth.currentUser.uid);
};

export {
    auth,
    db,
    storage,
    firebase,
    httpsRequest,
    FieldValue,
    usersCollection,
    isAdminUser,
    convertDate,
    uploadFile,
    uploadLocalFile,
    downloadFile,
};
