const getProductPlanIDByLabel = (plan) => {
    const plans = new Map([
        ['trial', '135916bd-f4db-4959-af10-a5b4f09725de'],
        ['basic', 'ad22aff7-2bcc-4121-9a19-e68a9554a1c7'],
        ['pro', '687607ab-2c28-4f6e-b6b9-d296d75ad05c'],
    ]);

    return plans.get(plan) || null;
};

const getProductPlanLabelByID = (plan) => {
    const plans = new Map([
        ['135916bd-f4db-4959-af10-a5b4f09725de', 'trial'],
        ['ad22aff7-2bcc-4121-9a19-e68a9554a1c7', 'basic'],
        ['687607ab-2c28-4f6e-b6b9-d296d75ad05c', 'pro'],
    ]);

    return plans.get(plan) || null;
};

export { getProductPlanIDByLabel, getProductPlanLabelByID };
