import { httpsRequest } from '@/utils/firebase';
import { reactive, toRefs } from 'vue';

export default async function useTickets(projectId) {
    const data = reactive({
        tickets: {
            open: [],
            closed: [],
        },
        isLoading: true,
    });

    try {
        const getZendeskTickets = await httpsRequest.httpsCallable('getZendeskTicketsByProjectId');
        const response = await getZendeskTickets({ project: projectId });
        const tickets = response.data.result.requests || [];

        const q = await Promise.all(tickets.map(async (ticket) => {
            const getZendeskTicketComments = await httpsRequest.httpsCallable('getZendeskTicketComments');
            const commentsResponse = await getZendeskTicketComments({ ticket: ticket.id });
            const { users, comments } = commentsResponse.data.result;

            let column;
            let statusColor;
            let statusText;

            if (ticket.status === 'solved') {
                column = 1;
                statusColor = 'green';
                statusText = 'Arkiveret';
            } else {
                const agentId = users.reduce((acc, u) => u.id);
                const answered = comments.reduce((acc, comment) => comment.author_id === agentId, false);
                column = answered ? 2 : 1;
                statusColor = answered ? 'green' : 'yellow';
                statusText = answered ? 'Besvaret' : 'Afventer svar';
            }

            return { column, statusColor, statusText, comments, ...ticket };
        }));

        data.tickets.open = q.filter((t) => t.status !== 'solved');
        data.tickets.closed = q.filter((t) => t.status === 'solved');
        data.isLoading = false;
    } catch (err) {
        console.log(err);
    }

    return { ...toRefs(data) };
}
