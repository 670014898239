<template>
    <div :class="{ 'form-message' : true, 'error' : message.error }" v-if="message" v-html="message.text"></div>
    <form ref="form" @submit.prevent="submit" novalidate :class="{ 'processing' : processing }">
        <slot/>
    </form>
</template>

<script>
export default {
    name: 'Form',
    emits: ['submit', 'attempt'],
    props: {
        processing: {
            type: Boolean,
            default: false,
        },
        message: {
            type: Object,
            default: null,
        },
    },
    methods: {
        submit() {
            // TODO: https://v3.vuejs.org/guide/component-custom-events.html#defining-custom-events
            if (this.isFormFieldsValid()) this.$emit('submit');
            else this.$emit('attempt');
        },
        isFormFieldsValid() {
            const fields = this.$refs.form.querySelectorAll('*:required, div.v3dp__datepicker input[readonly]');
            const invalidFields = [];

            for (let i = 0; i < fields.length; i += 1) {
                const field = fields[i];
                if (!field.checkValidity()) {
                    invalidFields.push(field);
                    field.parentNode.setAttribute('data-invalid', field.validationMessage);
                } else {
                    field.parentNode.removeAttribute('data-invalid');
                    field.parentNode.classList.remove('show-invalid');
                }
            }

            if (invalidFields.length) {
                const firstInvalidField = invalidFields[0];
                if (firstInvalidField.type === 'date' && firstInvalidField.previousElementSibling?.classList.contains('v3dp__datepicker')) {
                    firstInvalidField.previousElementSibling.querySelector('input').focus();
                } else if (firstInvalidField.type === 'checkbox') {
                    firstInvalidField.parentNode.classList.add('show-invalid');
                } else {
                    firstInvalidField.focus();
                }
            }

            return !invalidFields.length;
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/variables';

form {
    --column-gap: 16px;
    display: flex;
    gap: 50px var(--column-gap);
    flex-flow: row wrap;
    max-width: calc(var(--fieldset-max-width) * 2 + var(--column-gap));

    &:invalid button[type="submit"] {
        pointer-events: none;
        opacity: 0.3;
    }

    &.processing {
        pointer-events: none;

        button[type="submit"] {
            > * {
                opacity: 0;
            }

            &::after {
                content: "";
                height: 2.6rem;
                width: 2.6rem;
                border-radius: 50%;
                border: 3px solid rgb(var(--white-color));
                position: absolute;
                top: calc(50% - 1.3rem);
                left: calc(50% - 1.3rem);
                box-sizing: border-box;
                border-right-color: transparent;
                animation: spin 0.6s linear infinite;
            }
        }
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;

        @media @laptop-screen {
            max-width: var(--fieldset-max-width);

            &.double {
                max-width: calc((var(--fieldset-max-width) * 2) + var(--column-gap));
                width: 70% !important;
            }

            &:not(:only-of-type) {
                width: calc(50% - var(--column-gap) / 2);
            }
        }

        legend {
            padding: 0 1rem;

            &.group {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &.group > span,
            &:not(.group) {
                font-size: 1.8rem;
                font-weight: var(--semibold-weight);
            }

            label.checkbox {
                display: flex;
                font-weight: initial;

                > i {
                    flex-shrink: 0;
                }
            }
        }

        > * {
            position: relative;
            width: 100%;
            max-width: var(--fieldset-max-width);
            box-sizing: border-box;

            &.checkbox {
                display: flex;

                > i {
                    flex-shrink: 0;
                }

                > span {
                    margin-top: 3px;
                }
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &[data-tooltip]::before,
            &[data-invalid]:not([data-invalid=""])::before {
                position: absolute;
                top: -10px;
                left: 6px;
                border-radius: var(--input-border-radius);
                color: rgb(var(--white-color));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 20px;
                font-size: 1.2rem;
                box-sizing: border-box;
                line-height: 1.1;
                font-weight: var(--semibold-weight);
                pointer-events: none;
            }

            &[data-tooltip]::after,
            &[data-invalid]:not([data-invalid=""])::after {
                content: "";
                position: absolute;
                top: -5px;
                left: 25px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                pointer-events: none;
            }

            &[data-tooltip]::before {
                content: attr(data-tooltip);
                background: rgb(var(--grey-status-background-color));
                color: rgb(var(--grey-status-text-color));
            }

            &[data-tooltip]::after {
                border-top: 5px solid rgb(var(--semi-light-grey-color));
            }

            &[data-invalid]:not([data-invalid=""]) {
                &::before {
                    content: attr(data-invalid);
                    background: rgb(var(--red-status-background-color));
                    color: rgb(var(--red-status-text-color));
                }

                input, select,
                div.v3dp__datepicker div.v3dp__popout {
                    border-color: rgb(var(--red-status-background-color));
                }
            }

            &[data-invalid]:not([data-invalid=""])::after {
                border-top: 5px solid rgb(var(--red-status-background-color));
            }

            &[data-invalid]:not([data-invalid=""])::before,
            &[data-tooltip]::before,
            &[data-invalid]:not([data-invalid=""])::after,
            &[data-tooltip]::after {
                opacity: 0;
                transform: translate3d(0, calc(-100% + 10px), 0);
                transition: all var(--time-transition) var(--cubic-transition);
            }

            &:focus-within::before,
            &:focus-within::after,
            &.show-invalid::before,
            &.show-invalid::after
            // &:hover::after, // Tjek om dette er den bedste løsning.
            // &:hover::before {
            {
                opacity: 1 !important;
                transform: translate3d(0, -100%, 0) !important;
            }
        }

        .half {
            width: calc(50% - 5px);

            div.v3dp__datepicker div.v3dp__popout {
                width: calc(200% + 10px);
            }
        }

        .expand {
            max-width: none;
        }

        .radio-list > *:not(:last-child) {
            margin-right: 40px;
        }
    }

    input,
    select,
    textarea {
        appearance: none;
        -webkit-appearance: none;
    }

    input[type="file"],
    input[type="date"] {
        &[data-added="true"] {
            ~ button.small {
                opacity: 1;
                pointer-events: auto;
            }
        }

        ~ button.small {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 50%;
            right: 0;
            width: 40px;
            height: 30px;
            transform: translateY(-50%);
        }
    }

    input[type="file"][data-added="true"] ~ label,
    input[type="date"][data-added="true"],
    div.v3dp__datepicker[data-added="true"] input,
    input[type="date"][data-added="true"] ~ label {
        width: calc(100% - 20px);
    }

    input[type="file"] {
        &[data-added="true"] ~ label {
            background: rgb(var(--white-color));
            font-weight: inherit;

            &::after {
                content: attr(data-file);
            }

            svg {
                color: rgb(var(--primary-color));
            }
        }

        ~ label {
            align-items: center;
            background: rgb(var(--light-grey-color));
            cursor: pointer;
            font-weight: var(--semibold-weight);
            color: rgb(var(--dark-grey-color));

            &::after {
                content: attr(data-label);
            }

            svg {
                margin-right: 15px;
                font-size: var(--size-icon);
            }
        }
    }

    input[type="checkbox"] {
        &:checked ~ i {
            background: rgb(var(--primary-color));
            border-color: rgb(var(--primary-color));
            animation: zoom-in-out 0.3s ease;

            svg {
                stroke-dashoffset: 0;
            }
        }

        ~ i {
            display: block;
            margin: 0 1.7rem 0 0;
            align-items: center;
            justify-content: center;
            font-size: 1.15rem;
            color: transparent;
            width: 20px;
            height: 20px;
            border-width: 1px;
            border-style: solid;
            border-radius: 5px;
            box-sizing: border-box;
            overflow: hidden;
            box-shadow: 0 10px 20px rgba(var(--semi-light-grey-color), 35%);
            transition: all var(--time-transition) var(--cubic-transition);
            perspective: 100px;

            svg {
                color: rgb(var(--white-color));
                position: absolute;
                top: 4px;
                left: 3px;
                fill: none;
                stroke: #fff;
                stroke-dasharray: 16px;
                stroke-dashoffset: 16px;
                transition: all 0.3s ease;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        ~ span {
            font-size: 1.6rem;

            a {
                color: rgb(var(--primary-color));
            }
        }
    }

    input[type="radio"] {
        &:checked ~ span::before {
            background-color: rgb(var(--primary-color));
        }

        ~ span::before {
            content: "";
            display: inline-block;
            width: 19px;
            height: 19px;
            border-radius: 50%;
            border: 1px solid rgb(var(--primary-color));
            vertical-align: middle;
            margin-right: 1.7rem;
            padding: 3px;
            box-sizing: border-box;
            background-clip: content-box;
            transition: background-color var(--time-transition) var(--cubic-transition);
        }
    }

    input[type="file"],
    input[type="checkbox"],
    input[type="radio"] {
        display: none;

        ~ label,
        ~ figure,
        ~ i {
            display: inline-flex;
            border-color: rgb(var(--semi-light-grey-color));
        }
    }

    input,
    select,
    input[type="file"] ~ label,
    textarea,
    input[data-type="price"] ~ label,
    input[type="date"] ~ label {
        font-family: var(--standard-font);
        font-size: 1.6rem;
        line-height: 1.3;
        padding: 0 25px;
        box-sizing: border-box;
    }

    input,
    select,
    input[type="file"] ~ label,
    textarea {
        height: var(--action-button-height);
        box-shadow: 0 10px 20px rgba(var(--semi-light-grey-color), 35%);
        border-radius: var(--input-border-radius);
        border-width: 1px;
        border-style: solid;
        transition: color var(--time-transition) var(--cubic-transition), border-color var(--time-transition) var(--cubic-transition);
    }

    input,
    select,
    textarea {
        width: 100%;
        outline: none;
        border-color: rgb(var(--light-grey-color));
        background: rgb(var(--white-color));
        color: rgb(var(--semi-light-grey-color));

        &::placeholder {
            color: rgb(var(--semi-light-grey-color));
        }

        &:focus {
            border-color: rgb(var(--semi-light-grey-color));
            color: rgb(var(--dark-grey-color));
        }

        &:not([type="date"]):valid:not(:placeholder-shown) {
            border-color: rgb(var(--green-status-background-color));
        }

        &:valid,
        &:valid ~ svg {
            color: rgb(var(--dark-grey-color));
        }
    }

    select {
        ~ svg {
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            font-size: 1.2rem;
            color: rgb(var(--semi-light-grey-color));
            transition: color var(--time-transition) var(--cubic-transition);
        }

        option:disabled {
            color: rgb(var(--semi-light-grey-color));
        }
    }

    textarea {
        padding: 20px 25px;
        min-width: 100%;
        max-height: 500px;

        &.single {
            min-height: 63px;
        }

        &:not(.single) {
            height: auto;
            min-height: 140px;
        }
    }

    input[type="date"] {
        text-indent: -999px;

        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button {
            display: none;
        }

        &[data-added="false"] ~ label {
            color: rgb(var(--semi-light-grey-color));
        }
    }

    div.v3dp__datepicker[data-added="true"] input,
    input[type="date"][data-added="true"],
    input[type="file"][data-added="true"] ~ label {
        border-color: rgb(var(--green-status-background-color));
    }

    input[type="number"]::-webkit-inner-spin-button {
        display: none;
    }

    input[data-type="price"] {
        &:not(:placeholder-shown) ~ label::after {
            content: " " attr(data-currency);
            color: rgb(var(--dark-grey-color));
            white-space: pre;
        }

        ~ label {
            color: transparent;
            user-select: none;
        }
    }

    input[data-type="price"],
    input[type="date"] {
        ~ label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            display: flex;
            align-items: center;
        }
    }

    input[type="password"]:not(:placeholder-shown) {
        font-family: Arial;
        letter-spacing: 0.1em;
        font-size: 2.5rem;
    }

    div.v3dp__datepicker {
        &[data-added="true"] div.v3dp__popout {
            border-color: rgb(var(--green-status-background-color));
        }

        input {
            text-indent: -999px;
        }

        div.v3dp__popout {
            position: absolute;
            bottom: -10px;
            left: 0;
            transform: translateY(100%);
            z-index: 1;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            border-radius: var(--input-border-radius);
            border: 1px solid rgb(var(--semi-light-grey-color));
            background: rgb(var(--white-color));
            color: rgb(var(--dark-grey-color));
            box-shadow: 0 10px 20px rgba(var(--semi-light-grey-color), 35%);
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            border-radius: 50%;
            transition: border-color var(--time-transition) var(--cubic-transition);

            &:hover {
                background: rgb(var(--light-grey-color));
            }
        }

        div.v3dp__heading {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
            margin-bottom: 14px;

            button {
                min-width: 50px;
            }

            .v3dp__heading__center {
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-weight: var(--semibold-weight);
                padding: 0 20px;
                border-radius: var(--input-border-radius);
            }

            button.v3dp__heading__button {
                font-size: var(--size-icon);

                &:disabled {
                    pointer-events: none;

                    svg {
                        stroke: rgb(var(--semi-light-grey-color));
                    }
                }

                svg {
                    height: 1em;
                    width: 1em;
                    stroke: rgb(var(--dark-grey-color));
                }
            }
        }

        hr.v3dp__divider {
            display: none;
        }

        div.v3dp__body {

            div.v3dp__subheading {
                font-weight: var(--semibold-weight);
                color: rgb(var(--grey-color));
                margin-bottom: 10px;
            }

            div.v3dp__subheading,
            div.v3dp__elements {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                text-align: center;
                font-size: 1.2rem;
                text-transform: uppercase;
            }

            div.v3dp__elements button {
                height: 50px;

                &:disabled {
                    color: rgb(var(--semi-light-grey-color));
                    pointer-events: none;
                }

                &.selected {
                    background: rgb(var(--green-status-background-color));
                    color: rgb(var(--green-status-text-color));
                    font-weight: 700;
                }
            }
        }
    }
}

.form-message {
    padding: 20px 25px;
    border-radius: var(--input-border-radius);
    margin-bottom: 20px;
    color: rgb(var(--green-status-text-color));
    border: 1px solid;
    line-height: 1.2;
    max-width: var(--fieldset-max-width);
    box-sizing: border-box;

    &.error {
        color: rgb(var(--red-status-text-color));
    }
}

@keyframes spin {
    0% { transform: rotate(0); }
    100% { transform: rotate(365deg); }
}

@keyframes zoom-in-out {
    50% {
        transform: scale(0.9);
    }
}

</style>
