<template>
    <svg v-if="isSelectedProject" viewBox="0 0 40 40" width="1.1em" height="1.1em">
        <circle cx="20" cy="20" r="20" :fill="color" />
        <text x="50%" y="55%" dominant-baseline="middle" text-anchor="middle" fill="currentColor">{{ initials }}</text>
    </svg>
    <Icon v-else name="bars"/>
</template>

<script>
import useState from '@/store';
import { computed } from 'vue';
import Icon from '@/components/Icon.vue';

export default {
    name: 'Initials',
    components: {
        Icon,
    },
    setup() {
        const { project } = useState();

        function getInitials() {
            const words = project.value.name.split(' ');
            const characters = (words.length > 1)
                ? words[0].charAt(0) + words[1].charAt(0)
                : words[0].substr(0, 2);
            return characters.toUpperCase();
        }

        const isSelectedProject = computed(() => !!project.value.id);
        const color = computed(() => project.value.color);
        const initials = computed(() => getInitials());

        return { isSelectedProject, color, initials };
    },
};
</script>

<style scoped lang="less">
svg text {
    color: rgb(var(--white-color));
    font-weight: var(--bold-weight);
}
</style>
