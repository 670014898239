import { reactive, toRefs, readonly } from 'vue';
import { httpsRequest, usersCollection } from '@/utils/firebase';
import useTickets from '@/utils/useTickets';
import { getProductPlanLabelByID } from '@/utils/productPlanIDs';

const state = reactive({
    user: {},
    project: {},
    subscription: {},
    collections: {},
    modalData: {},
    tickets: {
        open: [],
        closed: [],
        isLoading: true,
    },
});

async function getLastViewedProject() {
    try {
        const response = await usersCollection
            .doc(state.user.id)
            .collection('projects')
            .orderBy('viewed', 'desc')
            .limit(1)
            .get();
        const data = response.docs[0];
        return { id: data.id, ...data.data() };
    } catch {
        return undefined;
    }
}

export default function useState() {
    const updateLocalStorage = () => {
        window.localStorage.setItem(state.user.id, JSON.stringify({ ...state }));
    };

    async function getAndSaveQuestions() {
        const { tickets, isLoading } = await useTickets(state.project.id);
        state.tickets.open = tickets.value.open;
        state.tickets.closed = tickets.value.closed;
        state.tickets.isLoading = isLoading.value;
        updateLocalStorage();
    }

    const setProjectAndCollections = async (projectData = {}, collectionsData = {}) => {
        state.project = projectData;
        state.collections = collectionsData;
        state.tickets = { open: [], closed: [], isLoading: true };
        await getAndSaveQuestions();
        updateLocalStorage();
    };

    const updateProject = (projectObject) => {
        state.project = { ...state.project, ...projectObject };
        updateLocalStorage();
    };

    const removeProject = () => {
        state.project = {};
        state.collections = {};
        updateLocalStorage();
    };

    const clearState = () => {
        state.user = {};
        state.project = {};
        state.collections = {};
        state.modalData = {};
        state.tickets = { open: [], closed: [], isLoading: true };
    };

    const updateCollections = (collectionKey, length) => {
        state.collections[collectionKey] = length;
        updateLocalStorage();
    };

    const updateTickets = (tickets) => {
        state.tickets = { ...tickets, isLoading: false };
        updateLocalStorage();
    };

    async function getAndSetUserData() {
        const user = await usersCollection.doc(state.user.id).get();
        state.user = { ...state.user, ...user.data() };
        state.user.name = `${ state.user.firstname } ${ state.user.lastname }`;
        updateLocalStorage();
    }

    async function setLastViewedProjectIfExist() {
        const lastViewedProject = await getLastViewedProject();
        if (lastViewedProject) {
            const { id, name, color, address, collections, unforeseenExpenses } = lastViewedProject;
            await setProjectAndCollections({ id, name, color, address, unforeseenExpenses }, collections);
        } else {
            updateLocalStorage();
        }
    }

    async function getActiveSubscription() {
        try {
            const getInformation = await httpsRequest.httpsCallable('getUpodiSubscription');
            const response = await getInformation(state.user.ucid);
            const subscription = response.data;

            let productPlan = getProductPlanLabelByID(subscription?.ProductPlanID);
            productPlan = productPlan === 'trial' ? 'pro' : productPlan;

            state.subscription = subscription ? { ...subscription, isActive: subscription.Status === 1, plan: productPlan } : { isActive: false, plan: null };
        } catch (err) {
            console.log(err);
        }
    }

    const updateSubscription = (data) => {
        state.subscription = { ...data, isActive: data.Status === 1 }; // TODO: Skal der være status bagerst?
        updateLocalStorage();
    };

    const initState = async (userID = '', guest = undefined) => {
        const localStorage = JSON.parse(window.localStorage.getItem(userID));
        state.user = { id: userID, ...localStorage?.user };

        if (localStorage) {
            if (localStorage?.project) {
                state.project = localStorage.project;
                state.collections = localStorage.collections;
                updateLocalStorage();
            } else {
                await setLastViewedProjectIfExist();
            }

            state.subscription = localStorage?.subscription || {};

            if (localStorage?.guest) {
                state.guest = localStorage.guest;
                updateLocalStorage();
            }
        } else {
            await setLastViewedProjectIfExist();

            if (guest) {
                state.guest = guest;
                updateLocalStorage();
            }
        }

        await getAndSetUserData();
        await getActiveSubscription();
        await getAndSaveQuestions();
    };

    const setModalData = (data = {}) => {
        state.modalData = data;
    };

    const clearModalData = () => {
        state.modalData = {};
    };

    const clearGuest = () => {
        delete state.guest;
    };

    return {
        ...toRefs(readonly(state)),
        initState,
        setProjectAndCollections,
        removeProject,
        updateProject,
        updateCollections,
        updateTickets,
        updateSubscription,
        clearState,
        setModalData,
        clearModalData,
        clearGuest,
    };
}
