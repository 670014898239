<template>
    <button :class="{ 'floating' : float, 'small' : small, 'secondary' : secondary, 'red' : red }"
            @click.stop="$emit('click')" :data-tooltip="tooltip" :disabled="disabled" :type="type">
        <template v-for="(icon, index) in icons">
            <Icon v-if="focusIcon === index"  :name="icon" :key="index"/>
        </template>
        <span v-if="label">{{ label }}</span>
    </button>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
    name: 'ActionButton',
    components: {
        Icon,
    },
    emits: ['click'],
    props: {
        submit: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: null,
        },
        icon: {
            type: [String, Array],
            default: null,
        },
        float: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        red: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        focusIcon: {
            type: Number,
            default: 0,
        },
    },
    setup(props) {
        const type = props.submit ? 'submit' : 'button';
        const icons = typeof props.icon === 'string' ? [props.icon] : props.icon;
        return { type, icons };
    },
};
</script>

<style scoped lang="less">
@import "../../assets/css/variables";

button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--standard-font);
    height: var(--action-button-height);
    font-size: 1.8rem;
    font-weight: var(--semibold-weight);
    border-radius: 30px;

    &:not(.secondary) {
        color: rgb(var(--white-color));
        background: rgb(var(--primary-color));
        box-shadow: 0 10px 20px rgba(var(--primary-color), 30%);
        transition: box-shadow var(--time-transition) var(--cubic-transition);

        &:not([disabled]):not(.red):hover {
            box-shadow: 0 5px 10px rgba(var(--primary-color), 60%);
        }

        &.red {
            background: rgb(var(--red-status-text-color));
            color: rgb(var(--red-status-background-color));
            box-shadow: 0 10px 20px rgba(var(--red-status-text-color), 30%);

            &:hover {
                box-shadow: 0 5px 10px rgba(var(--red-status-text-color), 60%);
            }
        }
    }

    span:only-child {
        margin: 0 7.5rem
    }

    &:not(.small) {
        min-width: var(--action-button-width);

        svg {
            font-size: 1.6em;

            + span {
                margin-left: 7.5rem;
            }
        }
    }

    &.small {
        height: 42px;
        min-width: 42px;
        font-size: 1.4rem;

        svg {
            font-size: var(--size-icon);

            + span {
                margin-left: 1.3rem;
            }
        }
    }

    &.secondary {
        background: rgb(var(--light-grey-color));
        border: 1px solid rgba(var(--dark-grey-color), 10%);
        transition: all var(--time-transition) var(--cubic-transition);

        &:not([disabled]):hover {
            color: rgb(var(--primary-color));
            border-color: rgba(var(--dark-grey-color), 20%);
        }

        &.red {
            color: rgb(var(--red-status-text-color));

            &:not([disabled]):hover {
                color: rgb(var(--darker-red-status-text-color)) !important;
            }
        }
    }

    &[disabled] {
        cursor: default;
    }

    // TODO: Følg op på margin til højre og bunden, efter at det er lavet til position:absolute.
    &.floating {
        --margin: 30px;
        position: absolute;
        bottom: calc(var(--menuitem-height) + var(--margin) + 20px);
        right: var(--margin);
        z-index: 1;

        &:hover::before {
            transform: translate3d(calc(-100% + 40px), -100%, 0);
        }

        &::before {
            transform: translate3d(calc(-100% + 40px), calc(-100% + 10px), 0);
        }

        aside + & {
            bottom: calc(var(--menuitem-height) + 42px + 75px + var(--margin));
        }

        ul.card-list li.card > & {
            right: 20px;
            bottom: 20px !important;
            transform: translate3d(0, 20px, 0);
            opacity: 0;
            transition: all var(--cubic-transition) var(--time-transition);
        }

        ul.card-list li.card:hover > & {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        @media @laptop-screen {
            right: 40px;
            bottom: 40px !important;
        }
    }
}
</style>
