<template>
    <label :data-tooltip="tooltip" :data-invalid="error" :class="{ 'show-invalid' : showInvalidMessage }">
        <input ref="input" :type="type" :required="required" v-model="value"
               :placeholder="placeholder" :autocomplete="autocomplete"
               @keyup="validate" @focus="$emit('focus')">
    </label>
</template>

<script>
export default {
    name: 'Input',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showInvalidMessage: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: null,
        },
        modelValue: [String, Number],
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', this.type === 'number' ? Number(value) : value);
            },
        },
    },
    methods: {
        validate(e) {
            if (this.required && e.code !== 'Tab') {
                this.error = this.value ? null : this.$refs.input.validationMessage;
            }
        },
    },
};
</script>
