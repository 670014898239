<template>
    <main @scroll="onScroll" :class="{ 'center' : center, 'grid' : grid }">
        <section v-if="center">
            <h1 v-if="showTitle">{{ dynamicTitle }}</h1>
            <slot/>
        </section>
        <template v-else>
            <div class="header">
                <h1 v-if="showTitle">{{ dynamicTitle }}</h1>
                <slot name="header"/>
            </div>
            <slot/>
        </template>
    </main>
</template>

<script>
import router from '@/router';

export default {
    name: 'Main',
    props: {
        title: {
            type: String,
            default: null,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        center: {
            type: Boolean,
            default: false,
        },
        grid: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const dynamicTitle = props.title ?? router.currentRoute.value.meta.title;
        return { dynamicTitle };
    },
    data() {
        return {
            offsetTop: 20,
        };
    },
    mounted() {
        this.updateOffsetTop();
    },
    watch: {
        showTitle() {
            this.$nextTick(() => this.updateOffsetTop());
        },
    },
    methods: {
        updateOffsetTop() {
            if (this.showTitle && !this.center) {
                this.offsetTop = this.$el.querySelector('h1')?.nextElementSibling?.offsetTop;
                this.onScroll();
            }
        },
        onScroll() {
            const offset = this.offsetTop - this.$el.scrollTop;
            this.$el.style.setProperty('--scroll-offset', `${ offset > 20 ? offset : 20 }px`);
        },
    },
};
</script>

<style scoped lang="less">
@import '../assets/css/variables';

main {
    position: relative;
    background: rgb(var(--white-color));
    border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    padding: var(--container-padding) var(--container-padding) calc(var(--container-padding) + 157px);

    @media @laptop-max-screen {
        box-shadow: 0 -10px 20px rgba(var(--semi-light-grey-color), 15%);

        &:only-child {
            grid-row: 2;
        }

        .header {
            display: none;
        }
    }

    @media @laptop-screen {
        --scroll-offset: 0;
        --scroll-left-push: calc(var(--card-list-gap) - var(--scroll-bar-width));
        padding: 60px 0 20px var(--card-list-gap);
        border-radius: 0;

        &::-webkit-scrollbar {
            background: rgba(var(--dark-grey-color), 10%);
            border: solid transparent;
            background-clip: padding-box;
            width: var(--card-list-gap);
            border-width: var(--scroll-offset) 0 20px var(--scroll-left-push);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(var(--dark-grey-color), 60%);
            border: solid transparent;
            background-clip: padding-box;
            border-width: var(--scroll-offset) 0 20px var(--scroll-left-push);
        }

        &:only-child {
            grid-column: 2;
        }

        &.center {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            section {
                min-width: 75%;
                min-height: 60%;
            }
        }

        &.grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-auto-rows: minmax(auto, 1fr);
            grid-gap: 20px;
            padding-left: 0;
            padding-top: 20px;

            @media @large-desktop-screen {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            .header {
                display: none;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;

            :deep(> *:last-child) {
                flex: 1;
                max-width: 50%;
            }
        }
    }
}
</style>
