<template>
    <router-view/>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style lang="less">
@import url('https://use.typekit.net/buw5huu.css');
@import './assets/css/variables';
@import './assets/css/animations';

:root {
    // COLORS:
    --white-color: 255, 255, 255;
    --black-color: 0, 0, 0;
    --primary-color: 48, 179, 74;
    --dark-grey-color: 74, 74, 74;
    --grey-color: 150, 150, 150;
    --semi-light-grey-color: 214, 214, 214;
    --light-grey-color: 247, 247, 247;

    --green-status-background-color: 215, 245, 221;
    --green-status-text-color: 48, 179, 74;
    --red-status-background-color: 252, 232, 232;
    --red-status-text-color: 253, 130, 130;
    --darker-red-status-text-color: 218, 68, 68;
    --yellow-status-background-color: 247, 239, 117;
    --yellow-status-text-color: 214, 165, 0;
    --grey-status-background-color: 217, 217, 217;
    --grey-status-text-color: 74, 74, 74;

    // FONTS
    --standard-font: rajdhani, sans-serif;
    --medium-weight: 500;
    --semibold-weight: 600;
    --bold-weight: 700;

    // CONTAINERS
    --header-height: 76px;
    --container-padding: 36px;
    --card-list-gap: 30px;
    --scroll-bar-width: 3px;
    --fieldset-max-width: 370px;

    // ICONS
    --size-icon: 1.16em;

    // TRANSITIONS
    --cubic-transition: cubic-bezier(0.22, 0.61, 0.36, 1);
    --time-transition: 0.28s;

    // BUTTONS
    --round-button-size: 54px;
    --action-button-height: 60px;
    --action-button-width: 80px;
    --menuitem-height: 56px;

    // BORDER RADIUS
    --large-border-radius: 40px;
    --medium-border-radius: 15px;
    --input-border-radius: 15px;

    @media @laptop-screen {
        --container-padding: 64px;
        --medium-border-radius: 20px;
    }
}

html, body {
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    padding: 0;
    background: rgb(var(--light-grey-color));
    font-family: var(--standard-font);
    font-weight: var(--medium-weight);
    font-size: 1.6rem;
    color: rgb(var(--dark-grey-color));
    font-style: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    display: grid;
    overscroll-behavior: contain;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media @laptop-max-screen {
        grid-template-rows: var(--header-height) 1fr;

        &.login-template {
            grid-template-rows: min-content min-content;
            overflow: auto;
            height: auto;
            position: static;
            background: rgb(var(--white-color));

            main {
                padding: var(--container-padding);
                grid-row: 2;
                border-radius: 0;
                text-align: center;
                box-shadow: none;
            }

            section {
                display: none;
            }

            aside {
                padding: calc(var(--container-padding) * 2) var(--container-padding);

                h1 {
                    text-align: center;
                }
            }
        }
    }

    @media @laptop-screen {
        background: rgb(var(--white-color));
        grid-template-columns: 280px 1fr auto;
        box-sizing: border-box;
        grid-gap: 35px;

        &.login-template {
            grid-template-columns: 1fr minmax(var(--fieldset-max-width), 40%);
            grid-gap: 0;

            main {
                background: rgb(var(--light-grey-color));
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                padding: var(--card-list-gap) 40px;
                font-weight: var(--semibold-weight);

                a {
                    color: rgb(var(--primary-color));
                }
            }

            aside {
                padding: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    @media @desktop-screen {
        grid-gap: 35px;
    }
}

h1, aside h2 {
    margin: 0 0 3.8rem 0;
    font-size: 4.4rem;
    line-height: 1.05;
    font-weight: var(--semibold-weight);

    @media @desktop-screen {
        font-size: 5.6rem;
        margin-bottom: 5.5rem;
    }
}

button {
    outline: 0;
    padding: 0;
    border: 0 none;
    background: none;
    color: inherit;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

svg.illustration {
    width: 62%;

    @media @laptop-screen {
        width: 66%;
    }

    .primary {
        fill: rgb(var(--primary-color));
    }
}

.placeholder {
    position: relative;
    display: inline-block;
    height: 1em;
    border-radius: 3px;
    background: rgba(var(--grey-color), 8%);
    vertical-align: middle;
    overflow: hidden;

    &::before {
        content: "";
        background: linear-gradient(90deg, transparent 0%, #000 50%, transparent 100%);
        opacity: .03;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform: translate3d(-100%, 0, 0);
        animation: placeholder 2s infinite ease-in-out;
    }
}

@keyframes placeholder {
    0% {
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}

span.status-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    padding: 0 12px;
    font-size: 1.2rem;
    height: 26px;
    text-transform: uppercase;
    font-weight: var(--bold-weight);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 95px;
    line-height: 1;

    &.smaller {
        height: 18px;
        min-width: 0;
        padding: 0 14px;
        font-size: 1rem;
        vertical-align: middle;
    }

    &.green {
        background: rgb(var(--green-status-background-color));
        color: rgb(var(--green-status-text-color));
    }

    &.red {
        background: rgb(var(--red-status-background-color));
        color: rgb(var(--red-status-text-color));
    }

    &.yellow {
        background: rgb(var(--yellow-status-background-color));
        color: rgb(var(--yellow-status-text-color));
    }

    &.light-grey {
        background: rgb(var(--light-grey-color));
        color: rgb(var(--semi-light-grey-color));
    }

    &.grey {
        background: rgb(var(--grey-status-background-color));
        color: rgb(var(--grey-status-text-color));
    }
}

.card {
    font-size: 1.4rem;
    line-height: 1.3;

    p {
        margin: 1.3rem 0 0;

        .floating-icon {
            position: relative;

            svg {
                position: absolute;
                top: 0;
                left: -2.2rem;
                font-size: 0.8em;
                color: rgb(var(--grey-color));
            }
        }

        a:hover {
            text-decoration: underline;
        }

        small i {
            font-style: normal;
            color: rgb(var(--grey-color));
        }

        &.columns {
            display: flex;
            justify-content: space-between;

            span {
                flex: 1;

                &.longer {
                    flex: 1.7;
                }

                &:only-child {
                    margin-left: auto;
                }

                &:last-child {
                    text-align: right;
                }

                &.align-left {
                    text-align: left;
                    flex: 0.8;
                    margin-left: 0;
                }
            }
        }

        .disabled {
            opacity: .3;
        }

        .grey {
            color: rgb(var(--grey-color));
        }

        .red {
            color: rgb(var(--red-status-text-color));
        }
    }

    strong {
        font-weight: var(--semibold-weight);
    }

    time {
        display: inline-block;
        margin-top: 0.6rem;
        color: rgb(var(--grey-color));
    }

    small {
        font-size: 1.2rem;
        font-weight: var(--bold-weight);
        text-transform: uppercase;
        color: rgb(var(--primary-color));
    }

    .larger {
        font-size: 1.8rem;
    }

    > button {
        margin: 1.3rem 0 0;
        float: right;
    }
}

p[dir="auto"] {
    margin-top: 0;
}

p.format a {
    text-decoration: underline;
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 160px;
}

[data-tooltip] {
    position: relative;

    &::before {
        content: attr(data-tooltip);
        position: absolute;
        top: -10px;
        left: 50%;
        border-radius: var(--input-border-radius);
        color: rgb(var(--grey-status-text-color));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        font-size: 1.2rem;
        box-sizing: border-box;
        line-height: 1.1;
        font-weight: var(--semibold-weight);
        pointer-events: none;
        background: rgb(var(--grey-status-background-color));
        z-index: 1;
        max-width: 220px;
        width: max-content;
        white-space: pre-line;
    }

    &::after {
        content: "";
        position: absolute;
        top: -5px;
        left: calc(~"50% - 5px");
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgb(var(--semi-light-grey-color));
        pointer-events: none;
    }

    &::before,
    &::after {
        opacity: 0;
        transform: translate3d(0, calc(-100% + 10px), 0);
        transition: all var(--time-transition) var(--cubic-transition);
    }

    button&::before,
    .status-label&::before {
        transform: translate3d(-50%, calc(-100% + 10px), 0);
    }

    button&:hover::before,
    .status-label&:hover::before {
        transform: translate3d(-50%, -100%, 0);
    }

    button&:hover::after,
    .status-label&:hover::after {
        transform: translate3d(0, -100%, 0);
    }

    button&:hover::before,
    button&:hover::after,
    .status-label&:hover::before,
    .status-label&:hover::after {
        opacity: 1;
    }
}

div.aside.scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: var(--container-padding) var(--container-padding) calc(var(--container-padding) * 2 + 40px);
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;

    @media @laptop-screen {
        padding: var(--container-padding) 40px var(--container-padding) var(--container-padding);
        border-radius: var(--large-border-radius) 0 0 var(--large-border-radius);

        --scroll-right-push: calc(20px - var(--scroll-bar-width));
        &::-webkit-scrollbar {
            background: rgba(var(--dark-grey-color), 10%);
            border: solid transparent;
            background-clip: padding-box;
            width: 20px;
            border-width: var(--container-padding) var(--scroll-right-push) var(--container-padding) 0;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(var(--dark-grey-color), 60%);
            border: solid transparent;
            background-clip: padding-box;
            border-width: var(--container-padding) var(--scroll-right-push) var(--container-padding) 0;
        }
    }

    &.floating {
        padding-bottom: calc(20px + var(--menuitem-height) + 30px /* action-button-margin */ + 35px + var(--action-button-height));
    }

    span.status-label {
        margin-bottom: 20px;
    }

    h1 {
        font-size: 3.6rem;
        font-weight: var(--semibold-weight);
        line-height: 0.9;
        margin-bottom: 0;

        &:first-child {
            margin-bottom: 3.5rem;
            + p {
                max-width: var(--fieldset-max-width);
                margin-top: -2rem;
                margin-bottom: 3.5rem;
            }
        }
    }

    time {
        margin-top: 0;
    }

    h2 {
        font-weight: var(--semibold-weight);
        margin: 3rem 0 0;
        font-size: 2.4rem;
        line-height: 1.1;
    }

    ul.card-list.inline {
        margin: 2.2rem 0;
    }

    footer {
        margin-top: 44px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        button {
            width: 100%;

            &:not(:only-child) {
                margin-top: 16px;
            }

            &.secondary:not(:only-child) {
                width: calc(50% - 8px);
            }
        }
    }
}

ul.card-list.compact {
    button {
        display: none;
    }

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
    }

    &.contracts li {
        flex-direction: row-reverse;
        padding: 16px 20px;

        .status-label {
            display: inline-flex;
            position: unset;
            transform: none;
        }
    }
}

</style>
