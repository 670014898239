import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { uploadFile } from '@/utils/firebase';

export default {
    methods: {
        formatNumberToPrice(number = 0, showCurrency = true, currency = 'DKK', addTax = false) {
            const formatter = new Intl.NumberFormat('da-DK', {
                currency, minimumFractionDigits: 2,
            });

            const fraction = new Intl.NumberFormat('da-DK', {
                currency, minimumFractionDigits: 0,
            });

            const numberInclTax = addTax ? number * 1.25 : number;
            const price = numberInclTax % 1 === 0 ? fraction.format(numberInclTax) : formatter.format(numberInclTax);

            return showCurrency ? `${price} ${currency}` : price;
        },
        removeNullValues(object) {
            const array = Object.entries(object).filter((field) => field[1] !== null);
            return Object.fromEntries(array);
        },
        formatDateToFnsDate(date = null, formatString = 'd. LLLL yyyy') {
            return date ? format(date, formatString, { locale: da }) : null;
        },
        getPaymentTypeLabel(type = 'allocated') {
            const types = new Map([
                ['fixed', 'Fast pris'],
                ['estimate', 'Overslag'],
                ['allocated', 'Egen'],
                ['hourly', 'Timepris'],
            ]);

            return types.get(type);
        },
        getContractStatusObject(state = '') {
            const status = new Map([
                ['SENT', {
                    color: 'yellow',
                    label: 'Sendt',
                }],
                ['ACCEPTED', {
                    color: 'green',
                    label: 'Accepteret',
                }],
                ['REJECTED', {
                    color: 'red',
                    label: 'Afvist',
                }],
            ]);

            return status.get(state) || { color: 'grey', label: 'Kladde' };
        },
        uploadOrUseLocalFile(file, projectId = this.project.id) {
            return file instanceof File ? uploadFile(file, file.name, projectId) : file;
        },
        formatText(text) {
            const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
            return text ? text.replaceAll(regex, '<a href="$1">$1</a>') : null;
        },
    },
};
