import useState from '@/store';
import { usersCollection } from '@/utils/firebase';

function throwError(description) {
    throw new Error(`Missing ${description} as parameter in function.`);
}

const { user, project } = useState();

const projectsCollection = () => usersCollection.doc(user.value.id).collection('projects');
const budgetsCollection = () => projectsCollection().doc(project.value.id).collection('budgets');
const expensesCollection = (budgetDocId = throwError('budget document id')) => budgetsCollection().doc(budgetDocId.toString()).collection('expenses');
const contractsCollection = () => projectsCollection().doc(project.value.id).collection('contracts');
const mailsCollection = (contractDocId = throwError('contract document id')) => contractsCollection().doc(contractDocId.toString()).collection('mails');
const additionsCollection = (contractDocId = throwError('contract document id')) => contractsCollection().doc(contractDocId.toString()).collection('additions');

const additionMailsCollection = (contractDocId = throwError('contract document id'), additionDocId = throwError('addition document id')) => contractsCollection()
    .doc(contractDocId.toString())
    .collection('additions')
    .doc(additionDocId.toString())
    .collection('mails');

const timelineCollection = () => projectsCollection().doc(project.value.id).collection('timeline');

export {
    usersCollection,
    projectsCollection,
    contractsCollection,
    budgetsCollection,
    expensesCollection,
    additionsCollection,
    mailsCollection,
    additionMailsCollection,
    timelineCollection,
};
