<template>
    <div :data-tooltip="tooltip" :data-invalid="error">
        <Datepicker
            v-model="pickerNativeInput"
            :locale="locale"
            :data-added="!!pickerNativeInput"
            :lowerLimit="from ? new Date(from) : null"
            :upperLimit="to ? new Date(to) : null"
            v-if="!isMobileOperatingSystem()"/>

        <input id="date"
               type="date"
               v-model="nativeDateInput"
               :required="required"
               :data-added="!!nativeDateInput"
               :min="from"
               :max="to"
               @change="validate"
               v-show="isMobileOperatingSystem()">

        <label>{{ readableDate || placeholder }}</label>
        <ActionButton icon="times" :small="true" @click="reset" :submit="false"/>
    </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import ActionButton from '@/components/buttons/ActionButton.vue';

export default {
    name: 'DateSelector',
    components: {
        ActionButton,
        Datepicker,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Date,
            default: null,
        },
        max: {
            type: Date,
            default: null,
        },
        shortFormat: {
            type: Boolean,
            default: false,
        },
        modelValue: Date,
    },
    data() {
        return {
            locale: da,
            error: null,
        };
    },
    emits: ['update:modelValue'],
    computed: {
        from() {
            return this.formatDate(this.min, 'yyyy-MM-dd');
        },
        to() {
            return this.formatDate(this.max, 'yyyy-MM-dd');
        },
        readableDate() {
            return this.formatDate(this.modelValue, this.shortFormat ? 'd. LLL yyyy' : 'd. LLLL yyyy');
        },
        nativeDateInput: {
            get() {
                return this.formatDate(this.modelValue, 'yyyy-MM-dd');
            },
            set(value) {
                this.value = value || null;
            },
        },
        pickerNativeInput: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.value = value;
            },
        },
        value: {
            get() {
                return new Date(this.modelValue);
            },
            set(value) {
                this.validate(value);
                this.$emit('update:modelValue', value ? new Date(value) : null);
            },
        },
    },
    methods: {
        isMobileOperatingSystem() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            const isWindowPhone = /windows phone/i.test(userAgent);
            const isAndroid = /android/i.test(userAgent);
            const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

            return isWindowPhone || isAndroid || isIOS;
        },
        validate(value) {
            if (this.required) this.error = value ? null : 'Udfyld dette felt';
        },
        reset() {
            this.value = null;
        },
        formatDate(date = null, formatString = '') {
            return date ? format(new Date(date), formatString, { locale: da }) : null;
        },
    },
};
</script>
