<template>
    <Main :center="true">
        <Form @submit="id ? update() : create()" :processing="processing">
            <fieldset>

                <Input type="text" placeholder="Projekt navn*" v-model="fields.name" :required="true"
                       tooltip="Fx. Renovering af badeværelse (kan ændres senere)"/>

                <Input type="text" placeholder="Projekt adresse*" v-model="fields.address" :required="true"
                       tooltip="Den fysiske adresse hvor projektet finder sted (kan ændres senere)"/>

                <DateSelector placeholder="Projekt startdato*" class="half" :shortFormat="true" v-model="fields.from" :required="true" :min="fields.from"
                              tooltip="Hvornår går du i gang? (kan ændres senere)"/>

                <DateSelector placeholder="Projekt slutdato*" class="half" :shortFormat="true" v-model="fields.to" :required="true" :min="fields.from"
                              tooltip="Hvornår forventer du at være færdig? (kan ændres senere)"/>

                <Price placeholder="Projekt budget*" class="half" v-model="fields.budget" :required="true"
                       tooltip="Har du et budget du skal holde dig indenfor? (kan ændres senere)"/>

                <Price placeholder="Uforudseelige udgifter" class="half" currency="%" :maximum-number="100" :allow-zero="true" v-model="fields.unforeseenExpenses" :required="true"
                       tooltip="Sætte normalt til 10% af det samlede projektbudget (kan ændres senere)"/>

                <Upload v-model="fields.image" label="Billede"
                        tooltip="Vedhæft et billede til dit projekt der vises på dit dashboard"/>

            </fieldset>
            <ActionButton :label="label" :disabled="!!beforeLastActivity" :tooltip="beforeLastActivity"/>
        </Form>
    </Main>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue';
import DateSelector from '@/components/inputs/Date.vue';
import Main from '@/components/Main.vue';
import Price from '@/components/inputs/Price.vue';
import { projectsCollection } from '@/utils/collections';
import useState from '@/store';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import methods from '@/utils/methods';
import { isAfter } from 'date-fns';
import Upload from '@/components/inputs/Upload.vue';
import { FieldValue, uploadFile } from '@/utils/firebase';

export default {
    name: 'CreateProject',
    components: {
        Main,
        DateSelector,
        Price,
        ActionButton,
        Form,
        Input,
        Upload,
    },
    mixins: [methods],
    setup() {
        const { setProjectAndCollections, updateProject } = useState();
        return { setProjectAndCollections, updateProject };
    },
    data() {
        return {
            fields: {
                name: null,
                address: null,
                from: null,
                to: null,
                budget: null,
                image: null,
                unforeseenExpenses: null,
            },
            processing: false,
            lastActivityEnd: new Date(),
        };
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        label() {
            return this.id ? 'Gem projekt' : 'Start nyt projekt';
        },
        fromDate() {
            return this.fields.from;
        },
        beforeLastActivity() {
            return isAfter(this.lastActivityEnd, this.fields.to) ? 'Slutdato skal være senere end sidste aktivitet i tidslinjen.' : null;
        },
    },
    watch: {
        fromDate() {
            this.fields.to = (this.fields.to && this.fields.from > this.fields.to) || this.fields.from === null ? this.fields.from : this.fields.to;
        },
    },
    async mounted() {
        if (this.id) {
            const project = await projectsCollection().doc(this.id).get();
            if (project.exists) {
                const data = project.data();
                this.fields.name = data.name;
                this.fields.address = data.address;
                this.fields.from = data.from.toDate();
                this.fields.to = data.to.toDate();
                this.fields.budget = data.budget;
                this.fields.image = data?.image || null;
                this.fields.unforeseenExpenses = data?.unforeseenExpenses ?? 10;
            }

            const activities = await projectsCollection().doc(this.id)
                .collection('timeline')
                .orderBy('to', 'desc')
                .get();

            if (activities.size) this.lastActivityEnd = activities.docs[0].data().to.toDate();
        } else {
            this.fields.unforeseenExpenses = 10;
        }
    },
    methods: {
        async create() {
            this.processing = true;

            try {
                const colors = ['#14B18C', '#2F137E', '#BE2069'];
                const random = Math.floor(Math.random() * colors.length);
                const color = colors[random];

                const { image } = this.$data.fields;
                delete this.$data.fields.image;

                const data = this.removeNullValues({ color, viewed: new Date(), ...this.$data.fields });
                const project = await projectsCollection().add(data);

                if (image) {
                    data.image = uploadFile(image, image.name, project.id);
                    await projectsCollection().doc(project.id).update({ image: data.image });
                }

                await this.setProjectAndCollections({ id: project.id, color, name: data.name, address: data.address });
                await this.$router.push('/projects');
            } catch (err) {
                this.processing = false;
                console.log(err);
            }
        },
        update() {
            this.processing = true;

            try {
                const data = this.removeNullValues(this.fields);

                const { image } = data;
                data.image = image ? this.uploadOrUseLocalFile(image, this.id) : FieldValue.delete();

                projectsCollection().doc(this.id).update(data).then(() => {
                    this.updateProject({ name: data.name });
                    this.$router.push('/projects');
                });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style scoped lang="less">
:deep(form) {
    display: block;
}

:deep(button[type="submit"]) {
    margin-top: 50px;
}

h2 {
    font-size: 2.4rem;
    font-weight: var(--semibold-weight);
    margin: 0 0 3.5rem;
}
</style>
