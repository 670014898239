<template>
    <a v-if="external" :href="to" target="_blank">
        <Icon :name="icon"/>
    </a>
    <router-link v-else :to="to">
        <Initials v-if="icon === 'initials'"/>
        <Icon v-else :name="icon"/>
    </router-link>
</template>

<script>
import Icon from '@/components/Icon.vue';
import Initials from '@/components/Initials.vue';

export default {
    name: 'IconButton',
    components: {
        Icon,
        Initials,
    },
    props: {
        to: {
            type: String,
            default: '/',
        },
        icon: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const external = !props.to.indexOf('http') > 0;
        return { external };
    },
};
</script>

<style scoped lang="less">
    a {
        width: var(--round-button-size);
        height: var(--round-button-size);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        text-decoration: none;
    }
</style>
