<template>
    <div :data-tooltip="tooltip" :data-invalid="error">
        <input id="price"
               ref="input"
               type="text"
               data-type="price"
               @keypress="isNumeric"
               @keyup="validate"
               @input="input"
               @focusout="focusout"
               :placeholder="placeholder"
               inputmode="numeric"
               step="0.1"
               :required="required">
        <label for="price" :data-currency="currency">{{ text }}</label>
    </div>
</template>

<script>
import methods from '@/utils/methods';

export default {
    name: 'Price',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: String,
            default: 'DKK',
        },
        maximumNumber: {
            type: Number,
            default: null,
        },
        allowZero: {
            type: Boolean,
            default: false,
        },
        modelValue: [Number, String],
    },
    data() {
        return {
            error: null,
            text: '',
        };
    },
    emits: ['update:modelValue'],
    mixins: [methods],
    watch: {
        modelValue(value) {
            this.formatPrice(value);
        },
    },
    methods: {
        isNumeric(e) {
            const { value } = e.target;
            const number = parseFloat(e.key);
            const isFirstNumberZero = value.length === 0 && number === 0 && !this.allowZero;
            const isNotNumeric = Number.isNaN(number) && e.key !== ',';
            const isSecondComma = e.key === ',' && value.replace(/[^,]/g, '').length >= 1;
            const moreThanTwoDigits = value.split(',')[1]?.length >= 2;

            if (isNotNumeric || isFirstNumberZero || isSecondComma || moreThanTwoDigits) e.preventDefault();
        },
        validate(e) {
            if (this.required && e.code !== 'Tab') {
                this.error = this.price ? null : this.$refs.input.validationMessage;
            }
        },
        input(e) {
            this.text = e.target.value;
        },
        focusout(e) {
            const { value } = e.target;
            let number = value.replaceAll('.', '').replaceAll(',', '.');
            number = number ? parseFloat(number) : null;

            if (this.maximumNumber && number > this.maximumNumber) number = this.maximumNumber;

            this.formatPrice(number);
            this.$emit('update:modelValue', number);
        },
        formatPrice(value) {
            const price = this.formatNumberToPrice(value, false);
            this.$refs.input.value = price;
            this.text = price;
        },
    },
};
</script>

<style scoped lang="less">
div {
    position: relative;
}
</style>
